import React, {useState, useEffect} from "react";

export default function SoftshelljackePrimoPlusFront({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(lightContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -150));
  }, lightContrast);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <path
        fill={lightContrast}
        onClick={() => handleClick("base")}
        onMouseEnter={() => setLightContrastActive(true)}
        onMouseLeave={() => setLightContrastActive(false)}
        style={
          lightContrastActive && !preview
            ? {fill: adjust(lightContrast, 55)}
            : {fill: lightContrast}
        }
        d="M468.249,464.107c-0.228-3.488-3.683-55.631-6.823-68.18c-3.281-13.115-7.595-44.461-8.681-56.446
 c-1.108-12.125-15.222-92.421-18.504-100.07c-1.336-3.129-6.074-20.351-11.55-40.311c-7.671-27.913-17.211-62.672-21.046-71.603
 c-4.444-10.386-10.507-19.905-13.766-25.023c-1.315-2.064-2.445-3.846-2.499-4.172c0-1.478-12.919-12.191-25.402-18.439
 c-12.431-6.204-51.644-16.787-55.013-17.689c-0.087-1.815-0.598-12.962,0.011-19.319c0.163-1.749-1.521-4.346-2.553-5.737
 c-3.291-4.486-8.616-6.15-13.342-7.367c-6.227-1.597-12.464-2.803-18.537-3.575c-11.659-1.499-23.882-1.456-38.442,0.152
 c-8.126,0.891-14.755,2.075-20.872,3.727c-7.573,2.043-12.169,5.541-14.06,10.692c-0.25,0.695-0.444,1.392-0.598,1.988l-0.021,0.065
 l0.01,0.054c0.598,6.356,0.098,17.504,0.011,19.319c-3.378,0.902-42.592,11.485-55.011,17.689
 c-12.484,6.248-25.403,16.961-25.403,18.428c-0.054,0.337-1.194,2.119-2.51,4.183c-3.249,5.118-9.312,14.637-13.766,25.023
 c-3.825,8.931-13.375,43.69-21.045,71.603c-5.477,19.96-10.204,37.181-11.551,40.311c-3.271,7.649-17.396,87.946-18.493,100.07
 c-1.096,11.985-5.411,43.331-8.682,56.446c-3.14,12.549-6.595,64.693-6.822,68.18c-0.142,0.109-0.24,0.282-0.251,0.489
 c-0.021,0.337,0.152,0.641,0.456,0.739c20.265,6.997,34.085,8.866,43.092,8.866c4.608,0,7.943-0.489,10.235-1.032
 c0.652-0.152,1.108-0.728,1.206-1.456h0.033c0.033-0.185,0.945-5.063,2.314-12.342l0.055-0.087
 c-0.012-0.012-0.023-0.012-0.034-0.023c3.988-21.078,11.876-62.107,14.027-68.137c2.456-6.867,18.906-67.594,21.025-79.251
 c1.532-8.421,11.452-38.313,17.635-54.969c-0.109,14.462-1.097,80.35-1.108,81.056l-2.086,81.012
 c-0.011,0.141-1.793,14.657-1.619,22.263c0.109,4.792,6.704,8.692,12.224,11.115c3.803,1.674,7.877,2.749,12.105,3.217l72.504,7.985
 c0.141,1.021,0.608,2.195,1.924,2.782c1.379,0.619,9.072,1.043,17.068,1.043c6.617,0,13.43-0.293,17.037-1.021l0.13-0.022
 l0.077-0.109c0.879-1.184,1.096-2.499,1.118-3.508l35.041-3.771c3.141-0.338,6.356-0.63,9.464-0.902
 c8.193-0.728,16.669-1.477,24.827-3.281l0.892-0.195c7.127-1.555,20.393-4.433,20.589-13.333c0.12-5.465-0.749-14.363-1.271-19.177
 c-0.239-2.161-0.39-4.4-0.445-6.64l-1.988-77.458c-0.011-0.706-1.01-66.594-1.118-81.056c6.181,16.657,16.113,46.548,17.645,54.969
 c2.118,11.657,18.557,72.384,21.014,79.251c2.161,6.03,10.05,47.059,14.038,68.137c-0.011,0.011-0.023,0.011-0.034,0.023
 l0.043,0.087c1.38,7.279,2.282,12.157,2.325,12.342h0.022c0.109,0.728,0.565,1.304,1.217,1.456
 c2.282,0.543,5.628,1.032,10.235,1.032c8.998,0,22.828-1.869,43.092-8.866c0.293-0.098,0.478-0.402,0.456-0.739
 C468.488,464.39,468.39,464.216,468.249,464.107z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M384.662,98.281c-1.119,3.988-2.206,7.943-3.272,11.897c-2.4-3.542-22.034-30.042-76.85-38.757
 c1.032-1.358,1.467-2.271,1.489-2.292c0.891-1.793-0.196-4.792-0.771-6.15c5.628,1.52,42.473,11.56,54.402,17.515
 C372.395,86.872,384.227,97.031,384.662,98.281"
      />
      <path
        fill="#EAEAEA"
        d="M381.216,110.83c-1.271,4.759-2.498,9.495-3.683,14.212c-0.075-0.206-0.162-0.413-0.249-0.608
 c-0.087-0.152-9.03-15.212-21.948-23.708c-17.656-11.626-37.225-18.232-61.476-20.764c5.183-2.652,8.377-5.715,10.17-7.91
 c58.467,9.181,76.84,38.582,77.014,38.887L381.216,110.83z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M445.67,299.432l-0.717-0.881c-6.236-7.638-14.408-13.461-23.654-16.862
 c-0.793-4.684-14.863-86.848-43.308-155.56c2.205-8.866,4.573-17.787,7.116-26.794c0.38,0.694,1.065,1.76,2.173,3.519
 c3.249,5.097,9.291,14.582,13.724,24.926c3.803,8.888,13.342,43.613,21.003,71.516c5.487,19.971,10.235,37.225,11.593,40.398
 C435.511,244.149,441.128,273.464,445.67,299.432"
      />
      <path
        fill={darkenColor}
        d="M467.815,464.661c-30.468,10.529-46.244,9.42-52.937,7.823c-0.477-0.12-0.674-0.576-0.706-0.956
 c-0.022-0.402,0.109-0.945,0.599-1.14c14.027-5.716,27.033-7.227,36.845-7.227C459.35,463.162,465.109,464.107,467.815,464.661"
      />
      <path
        fill={darkenColor}
        d="M87.352,471.528c-0.033,0.38-0.229,0.837-0.696,0.956c-6.682,1.597-22.448,2.706-52.871-7.845
 c6.216-1.25,27.978-4.433,52.969,5.748C87.242,470.583,87.383,471.126,87.352,471.528"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M360.942,209.781c-2.075,14.668-3.216,30.707-3.216,45.147c0,8.595,1.118,82.293,1.13,83.043l1.119,43.452
 c-2.238,8.203-9.214,13.374-11.159,14.678c-0.25-6.91-2.488-69.843-2.37-117.618c0.099-43.2,11.877-67.909,15.288-74.145
 C361.463,206.151,361.191,207.966,360.942,209.781"
      />
      <path
        fill="#EAEAEA"
        d="M337.647,400.327c-7.117,1.988-18.754,4.585-37.43,7.454c19.676-8.453,33.91-26.566,36.963-30.684
 L337.647,400.327z"
      />
      <path
        fill="#EAEAEA"
        d="M200.504,407.423c-17.711-2.89-29.326-5.541-36.606-7.508l0.445-22.828
 C167.364,381.161,181.25,398.828,200.504,407.423"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M152.718,396.101c-1.957-1.304-8.922-6.476-11.16-14.679l1.12-43.451c0.011-0.75,1.118-74.45,1.118-83.044
 c0-14.44-1.141-30.477-3.204-45.146c-0.261-1.815-0.523-3.629-0.794-5.444c3.401,6.237,15.191,30.945,15.289,74.145
 C155.195,326.258,152.967,389.191,152.718,396.101"
      />
      <path
        fill={darkenColor}
        d="M304.268,42.768c-0.141,0.445-2.771,8.444-11.919,9.04c-9.203,0.608-22.035-0.304-23.208-0.391
 c-0.337-1.358-1.184-1.988-2.619-1.956c-1.075,0.033-2.119,0.012-3.216-0.011l-0.326-0.01c-1.891-0.034-3.77-0.099-5.661-0.164
 c-2.803-0.087-5.715-0.185-8.574-0.195h-1.497l-0.501,0.01c-4.183,0.043-7.367,0.208-10.344,0.511l-0.608,0.055
 c-1.175,0.108-2.39,0.227-3.553,0.586c-0.054,0.022-0.118,0.045-0.174,0.065c-0.619,0.293-0.695,0.771-0.641,1.033v0.097
 c-2.064,0.152-13.495,0.956-22.241,0.369c-8.888-0.587-11.637-8.149-11.908-8.986c0.141-0.565,0.326-1.206,0.554-1.836
 c1.804-4.922,6.248-8.269,13.582-10.256c6.074-1.642,12.669-2.815,20.763-3.705c14.506-1.598,26.676-1.642,38.28-0.153
 c6.041,0.771,12.244,1.977,18.44,3.564c4.618,1.184,9.81,2.803,12.961,7.106C302.454,38.357,304.399,41.151,304.268,42.768"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M196.995,71.421c-54.828,8.715-74.461,35.215-76.862,38.757c-1.055-3.944-2.142-7.899-3.26-11.876
 c0.532-1.358,12.31-11.463,25.001-17.807c11.918-5.955,48.763-15.995,54.403-17.516c-0.576,1.358-1.662,4.357-0.773,6.139
 C195.517,69.15,195.951,70.052,196.995,71.421"
      />
      <path
        fill="#EAEAEA"
        d="M207.676,79.961c-24.252,2.532-43.82,9.138-61.488,20.764c-12.918,8.497-21.85,23.556-21.97,23.752
 c-0.076,0.185-0.152,0.369-0.228,0.553c-1.173-4.714-2.4-9.452-3.671-14.2l0.174,0.109c0.174-0.305,18.514-29.706,77.002-38.887
 C199.297,74.246,202.492,77.31,207.676,79.961"
      />
      <path
        fill={darkenColor}
        d="M228.146,214.682c-4.412-39.682-2.966-75.732-2.033-90.695L228.146,214.682z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M123.544,126.129c-28.511,68.832-42.582,151.246-43.32,155.592c-1.748,0.934-17.754,9.605-23.643,16.829
 l-0.728,0.881c4.553-25.968,10.17-55.283,12.082-59.738c1.358-3.173,6.097-20.427,11.581-40.398
 c7.672-27.903,17.201-62.628,21.015-71.516c4.432-10.344,10.474-19.829,13.712-24.926c1.119-1.759,1.793-2.825,2.184-3.508
 C118.959,108.342,121.327,117.263,123.544,126.129"
      />
      <path
        fill="#010101"
        d="M468.249,464.107c-0.228-3.488-3.683-55.631-6.823-68.18c-3.281-13.115-7.595-44.461-8.681-56.446
 c-1.108-12.125-15.222-92.421-18.504-100.07c-1.336-3.129-6.074-20.351-11.55-40.311c-7.671-27.913-17.211-62.672-21.046-71.603
 c-4.444-10.386-10.507-19.905-13.766-25.023c-1.315-2.064-2.445-3.846-2.499-4.172c0-1.478-12.919-12.191-25.402-18.439
 c-12.431-6.204-51.644-16.787-55.013-17.689c-0.087-1.815-0.598-12.962,0.011-19.319c0.163-1.749-1.521-4.346-2.553-5.737
 c-3.291-4.486-8.616-6.15-13.342-7.367c-6.227-1.597-12.464-2.803-18.537-3.575c-11.659-1.499-23.882-1.456-38.442,0.152
 c-8.126,0.891-14.755,2.075-20.872,3.727c-7.573,2.043-12.169,5.541-14.06,10.692c-0.25,0.695-0.444,1.392-0.598,1.988l-0.021,0.065
 l0.01,0.054c0.598,6.356,0.098,17.504,0.011,19.319c-3.378,0.902-42.592,11.485-55.012,17.689
 c-12.484,6.248-25.403,16.961-25.403,18.428c-0.054,0.337-1.194,2.119-2.51,4.183c-3.249,5.118-9.312,14.637-13.766,25.023
 c-3.825,8.931-13.375,43.69-21.045,71.603c-5.477,19.96-10.204,37.181-11.551,40.311c-3.27,7.649-17.396,87.946-18.493,100.07
 c-1.096,11.985-5.411,43.331-8.681,56.446c-3.14,12.55-6.595,64.693-6.822,68.18c-0.142,0.109-0.24,0.283-0.251,0.489
 c-0.021,0.337,0.152,0.641,0.456,0.739c20.265,6.997,34.085,8.866,43.092,8.866c4.608,0,7.943-0.489,10.235-1.032
 c0.652-0.152,1.108-0.728,1.206-1.456h0.033c0.033-0.185,0.945-5.063,2.314-12.342l0.055-0.087
 c-0.012-0.012-0.023-0.012-0.034-0.023c3.988-21.078,11.876-62.107,14.027-68.137c2.456-6.867,18.906-67.594,21.025-79.251
 c1.532-8.421,11.452-38.312,17.635-54.969c-0.109,14.462-1.097,80.35-1.108,81.056l-2.086,81.012
 c-0.011,0.141-1.793,14.657-1.619,22.263c0.109,4.792,6.704,8.692,12.224,11.115c3.803,1.674,7.877,2.749,12.105,3.217l72.504,7.985
 c0.141,1.021,0.608,2.195,1.924,2.782c1.379,0.619,9.072,1.043,17.068,1.043c6.617,0,13.43-0.293,17.037-1.021l0.13-0.022
 l0.077-0.109c0.879-1.184,1.096-2.499,1.118-3.508l35.041-3.771c3.141-0.338,6.356-0.63,9.464-0.902
 c8.193-0.728,16.669-1.477,24.827-3.281l0.892-0.194c7.127-1.555,20.393-4.433,20.589-13.333c0.119-5.465-0.749-14.363-1.271-19.177
 c-0.239-2.161-0.39-4.4-0.445-6.64l-1.988-77.458c-0.011-0.706-1.011-66.594-1.118-81.056c6.181,16.657,16.113,46.548,17.645,54.969
 c2.118,11.658,18.557,72.384,21.014,79.251c2.161,6.03,10.051,47.059,14.038,68.137c-0.011,0.011-0.023,0.011-0.034,0.023
 l0.043,0.087c1.38,7.279,2.282,12.157,2.325,12.342h0.022c0.109,0.728,0.565,1.304,1.217,1.456
 c2.282,0.543,5.628,1.032,10.235,1.032c8.998,0,22.828-1.869,43.092-8.866c0.293-0.098,0.478-0.402,0.456-0.739
 C468.488,464.39,468.39,464.216,468.249,464.107 M467.521,463.879c-3.281-0.641-10.115-1.662-19.156-1.358
 c-0.194-6.552-0.771-9.768-1.043-11.333c-0.043-0.25-0.087-0.467-0.109-0.641c6.921-0.826,17.701,1.228,19.482,1.587
 C467.14,458.1,467.434,462.499,467.521,463.879 M387.28,102.854c3.249,5.096,9.29,14.581,13.723,24.925
 c3.803,8.889,13.343,43.614,21.004,71.516c5.486,19.971,10.234,37.225,11.592,40.398c1.912,4.456,7.53,33.77,12.071,59.738
 l-0.717-0.88c-6.237-7.638-14.408-13.462-23.654-16.863c-0.793-4.683-14.864-86.847-43.308-155.56
 c2.205-8.866,4.573-17.787,7.116-26.794C385.487,100.029,386.171,101.094,387.28,102.854 M336.191,291.315h9.574
 c0.238,46.841,2.226,101.548,2.358,105.231c-0.349,0.217-2.62,1.554-9.779,3.586L336.191,291.315z M337.647,400.327
 c-7.117,1.988-18.754,4.585-37.43,7.454c19.676-8.453,33.91-26.566,36.963-30.684L337.647,400.327z M345.752,290.609h-10.279
 l1.685,85.315c-1.131,1.586-16.734,23.132-38.844,31.889l0.098,0.24c-7.542,1.13-16.19,2.314-26.088,3.499l0.129-254.044
 l-1.38-39.365c-0.12-3.64-0.239-7.279-0.359-10.909c-0.12-3.694-0.239-7.388-0.359-11.072c-0.108-3.618-0.217-7.225-0.326-10.832
 c0-0.087-0.011-0.164-0.011-0.251c0.706,0.119,2.358,0.152,6.367-0.13c6.791-0.792,12.126-2.456,16.287-4.41
 c24.655,2.445,44.439,9.039,62.28,20.774c12.778,8.41,21.633,23.328,21.698,23.437c0.196,0.478,0.402,0.956,0.598,1.434
 c-6.389,25.653-11.409,50.85-15.233,76.243c-0.793,1.271-16.167,26.642-16.276,76.058
 C345.73,282.438,345.73,286.491,345.752,290.609 M355.335,100.726c-17.656-11.627-37.225-18.233-61.476-20.765
 c5.184-2.651,8.377-5.715,10.17-7.91c58.467,9.181,76.84,38.583,77.014,38.887l0.174-0.109c-1.271,4.759-2.498,9.496-3.683,14.212
 c-0.075-0.206-0.163-0.412-0.25-0.608C377.197,124.281,368.254,109.222,355.335,100.726 M359.66,80.493
 c12.734,6.379,24.567,16.537,25.001,17.788c-1.119,3.988-2.206,7.942-3.27,11.896c-2.4-3.542-22.035-30.043-76.851-38.757
 c1.032-1.358,1.468-2.271,1.489-2.292c0.892-1.794-0.194-4.793-0.771-6.151C310.885,64.5,347.73,74.539,359.66,80.493
  M305.387,68.813c-0.054,0.131-6.15,12.756-29.065,15.43c-5.031,0.357-6.106,0.185-6.324,0.118l-0.261-9.225
 c-0.087-3.042-0.163-6.085-0.239-9.116c0-1.781-0.054-3.575-0.098-5.312c-0.076-2.522-0.151-5.119-0.064-7.672
 c0.011-0.337,0.021-0.511,0.031-0.608c0-0.12,0-0.196-0.031-0.293c1.607,0.12,8.648,0.587,15.775,0.587
 c2.499,0,4.998-0.054,7.28-0.206c6.954-0.456,10.322-4.976,11.725-7.649c-0.338,6.248,0.032,14.994,0.129,17.113l-0.293-0.076
 l0.348,0.707C304.322,62.642,306.387,66.814,305.387,68.813 M197.831,40.987c1.804-4.922,6.248-8.269,13.582-10.256
 c6.074-1.642,12.669-2.815,20.764-3.705c14.505-1.598,26.675-1.642,38.279-0.153c6.042,0.771,12.245,1.978,18.44,3.564
 c4.618,1.184,9.81,2.803,12.961,7.106c0.598,0.815,2.542,3.608,2.412,5.226c-0.141,0.445-2.771,8.442-11.919,9.04
 c-9.203,0.608-22.035-0.304-23.208-0.391c-0.336-1.358-1.184-1.988-2.619-1.956c-1.075,0.033-2.119,0.012-3.216-0.011l-0.326-0.011
 c-1.891-0.033-3.77-0.098-5.661-0.163c-2.803-0.087-5.715-0.185-8.573-0.196h-1.498l-0.501,0.011
 c-4.183,0.043-7.367,0.208-10.344,0.511l-0.608,0.055c-1.173,0.108-2.39,0.227-3.553,0.586c-0.054,0.022-0.118,0.045-0.174,0.065
 c-0.619,0.293-0.695,0.771-0.641,1.032v0.098c-2.064,0.152-13.495,0.956-22.241,0.369c-8.888-0.587-11.637-8.149-11.908-8.986
 C197.418,42.258,197.602,41.617,197.831,40.987 M164.376,375.924l1.673-84.957l0.011-0.359h-10.29
 c0.023-4.118,0.034-8.171,0.023-12.126c-0.121-49.405-15.483-74.776-16.277-76.058c-3.824-25.392-8.855-50.589-15.232-76.252
 c0.185-0.456,0.379-0.925,0.575-1.381c0.087-0.152,8.943-15.07,21.72-23.48c17.83-11.735,37.627-18.329,62.282-20.774
 c4.161,1.955,9.507,3.618,16.298,4.41c3.91,0.272,5.562,0.25,6.29,0.141c0.272,4.586,2.51,43.256,2.955,77.264
 c0.25,19.014,0.707,76.156,1.108,126.571c0.38,47.829,0.706,89.141,0.826,93.877c0.098,4.053,0.045,16.103-0.098,29.619
 c-12.778-1.532-23.708-3.075-33.042-4.562l0.022-0.046C181.098,399.056,165.495,377.51,164.376,375.924 M164.344,377.086
 c3.021,4.075,16.907,21.742,36.16,30.336c-17.711-2.89-29.326-5.54-36.606-7.508L164.344,377.086z M163.192,399.719
 c-6.421-1.771-9.236-2.945-9.79-3.194c0.141-3.857,2.12-58.456,2.369-105.21h9.572L163.192,399.719z M124.218,124.477
 c-0.076,0.185-0.152,0.369-0.228,0.554c-1.173-4.716-2.401-9.452-3.673-14.201l0.175,0.109c0.174-0.304,18.514-29.706,77.002-38.887
 c1.804,2.195,4.998,5.259,10.182,7.91c-24.253,2.532-43.82,9.138-61.488,20.765C133.268,109.222,124.337,124.281,124.218,124.477
  M197.222,62.61l0.348-0.707l-0.283,0.076c0.098-2.119,0.467-10.865,0.12-17.113c1.403,2.673,4.781,7.193,11.735,7.649
 c8.714,0.576,20.068-0.206,22.285-0.369c-0.021,3.249-0.185,26.381-0.011,32.238c-0.402,0.076-1.749,0.174-6.193-0.13
 c-22.926-2.684-29.021-15.308-29.087-15.451C195.147,66.814,197.2,62.642,197.222,62.61 M141.874,80.493
 c11.919-5.954,48.764-15.994,54.404-17.515c-0.577,1.358-1.663,4.357-0.773,6.139c0.011,0.033,0.445,0.934,1.49,2.303
 c-54.828,8.714-74.462,35.215-76.863,38.757c-1.054-3.944-2.14-7.899-3.26-11.876C117.406,96.944,129.184,86.839,141.874,80.493
  M67.935,239.693c1.358-3.173,6.097-20.427,11.583-40.398c7.671-27.902,17.2-62.627,21.014-71.516
 c4.433-10.344,10.474-19.829,13.713-24.925c1.118-1.76,1.792-2.825,2.183-3.51c2.532,8.997,4.9,17.917,7.117,26.783
 c-28.511,68.832-42.581,151.247-43.32,155.594c-1.748,0.933-17.754,9.605-23.643,16.829l-0.728,0.88
 C60.405,273.463,66.022,244.149,67.935,239.693 M34.829,452.135c1.792-0.36,12.57-2.413,19.491-1.587
 c-0.033,0.174-0.065,0.391-0.108,0.641c-0.283,1.565-0.849,4.781-1.055,11.322c-9.029-0.293-15.874,0.728-19.156,1.369
 C34.101,462.499,34.393,458.1,34.829,452.135 M86.656,472.484c-6.682,1.597-22.448,2.705-52.871-7.845
 c6.215-1.25,27.978-4.433,52.969,5.748c0.489,0.196,0.63,0.739,0.599,1.141C87.319,471.909,87.123,472.365,86.656,472.484
  M87.624,470.203c-0.164-0.196-0.36-0.369-0.599-0.467c-12.311-5.019-23.848-6.813-33.161-7.192
 c0.196-6.499,0.761-9.692,1.043-11.224c0.043-0.294,0.087-0.523,0.11-0.718c2.617,0.098,21.143,1.087,34.583,9.018
 C88.688,464.488,87.983,468.248,87.624,470.203 M124.75,311.742c-2.119,11.648-18.536,72.288-20.992,79.144
 c-2.194,6.139-10.018,46.807-14.016,67.995c-14.462-8.421-34.519-8.985-35.008-8.995c-6.791-0.913-17.515,1.066-19.851,1.522
 c1.271-17.168,3.673-46.375,5.911-55.306c3.281-13.146,7.606-44.548,8.692-56.554c0.445-4.889,3.01-20.872,6.117-38.692l1.521-1.858
 c6.117-7.496,23.415-16.646,23.589-16.732l0.152-0.087l0.033-0.175c0.141-0.848,14.082-84.652,42.929-154.723
 c6.878,27.783,12.169,55.033,16.059,82.599c2.066,14.626,3.205,30.597,3.205,44.983C136.974,271.094,126.347,302.984,124.75,311.742
  M142.678,337.971c0.011-0.75,1.119-74.449,1.119-83.043c0-14.441-1.141-30.479-3.204-45.147c-0.262-1.814-0.523-3.629-0.794-5.444
 c3.401,6.237,15.191,30.945,15.288,74.145c0.109,47.775-2.119,110.708-2.368,117.618c-1.957-1.304-8.922-6.476-11.16-14.679
 L142.678,337.971z M235.045,462.836l-72.374-7.964c-4.15-0.456-8.16-1.521-11.898-3.173c-5.335-2.336-11.702-6.063-11.8-10.485
 c-0.174-7.55,1.597-22.013,1.62-22.187l0.912-35.497c3.25,8.605,11.267,13.463,11.354,13.506l0.131,0.087H153l0.369,0.217
 l0.011-0.054c0.978,0.413,3.912,1.543,9.801,3.162l-0.011,0.206l0.706,0.022v-0.033c11.463,3.086,33.411,7.801,72.353,12.484
 c-0.228,20.808-0.662,44.809-1.097,48.557C235.122,461.729,235.034,462.206,235.045,462.836 M271.596,461.195
 c0.012,0.022,0.642,2.51-0.75,4.498c-8.225,1.607-31.434,0.945-33.531,0c-2.043-0.913-1.499-3.846-1.489-3.901
 c0.728-6.28,1.468-68.658,1.217-79.002c-0.118-4.737-0.467-48.036-0.826-93.877c-0.402-50.405-0.858-107.546-1.107-126.571
 c-0.468-35.215-2.837-75.406-2.977-77.643l0.064-0.11l-0.075-0.098c-0.174-5.682-0.012-29.369,0.011-32.401v-0.793l0.01-0.043
 l-0.021-0.053c-0.012-0.045,0.032-0.142,0.249-0.251l-0.151-0.315l0.272,0.283c1.053-0.337,2.237-0.444,3.367-0.554l0.62-0.065
 c2.954-0.304,6.116-0.455,10.267-0.5l0.501-0.011h1.498c2.848,0.011,5.749,0.109,8.552,0.196c1.89,0.065,3.78,0.131,5.66,0.163
 l0.337,0.011c1.054,0.022,2.152,0.045,3.249,0.011c1.108-0.053,1.673,0.38,1.912,1.445c0.12,0.5,0.185,0.652,0.206,0.762
 c-0.011,0.097-0.022,0.281-0.033,0.64c-0.087,2.575-0.01,5.183,0.065,7.704c0.043,1.738,0.099,3.531,0.099,5.302
 c0.075,3.053,0.151,6.097,0.238,9.138c0.087,3.401,0.196,6.791,0.293,10.192c0.11,3.608,0.217,7.215,0.326,10.833
 c0.12,3.683,0.24,7.378,0.359,11.072c0.12,3.629,0.24,7.269,0.359,10.909l1.38,39.354l-0.163,303.59L271.596,461.195z
  M360.844,415.441c0.054,2.26,0.206,4.509,0.445,6.693c0.522,4.802,1.391,13.657,1.271,19.08
 c-0.185,8.345-13.092,11.159-20.025,12.669l-0.902,0.196c-8.116,1.782-16.57,2.532-24.741,3.26
 c-3.107,0.283-6.324,0.565-9.475,0.902L272.432,462c-0.043-0.468-0.108-0.815-0.141-0.935l0.034-48.797
 c65.702-7.899,75.839-14.864,76.263-15.179l0.077-0.054c0.087-0.042,8.115-4.9,11.364-13.506L360.844,415.441z M358.856,337.971
 l1.12,43.451c-2.239,8.203-9.215,13.375-11.16,14.679c-0.249-6.91-2.488-69.843-2.369-117.618
 c0.098-43.201,11.876-67.909,15.288-74.145c-0.272,1.815-0.543,3.629-0.793,5.444c-2.075,14.668-3.216,30.706-3.216,45.147
 C357.726,263.522,358.845,337.221,358.856,337.971 M397.776,390.885c-2.456-6.856-18.872-67.496-20.992-79.144
 c-1.596-8.758-12.234-40.647-18.351-56.88c0.011-14.386,1.14-30.357,3.204-44.983c3.89-27.566,9.192-54.816,16.07-82.599
 c28.858,70.114,42.788,153.876,42.93,154.723l0.032,0.206l0.196,0.065c9.203,3.357,17.342,9.139,23.545,16.722l1.51,1.858
 c3.118,17.819,5.672,33.802,6.117,38.692c1.097,12.006,5.411,43.408,8.703,56.554c2.238,8.931,4.639,38.138,5.901,55.306
 c-2.326-0.456-13.05-2.435-19.852-1.522c-0.478,0.011-20.535,0.575-35.008,8.995C407.784,437.692,399.971,397.024,397.776,390.885
  M411.922,459.62c13.44-7.932,31.966-8.921,34.585-9.018c0.034,0.196,0.065,0.424,0.121,0.718c0.272,1.532,0.836,4.725,1.031,11.224
 c-9.312,0.379-20.84,2.173-33.15,7.192c-0.239,0.098-0.435,0.272-0.598,0.467C413.541,468.248,412.846,464.488,411.922,459.62
  M414.878,472.484c-0.477-0.12-0.674-0.576-0.706-0.956c-0.022-0.402,0.109-0.945,0.598-1.141
 c14.028-5.715,27.033-7.225,36.846-7.225c7.735,0,13.494,0.945,16.2,1.499C437.348,475.19,421.571,474.082,414.878,472.484"
      />
      <path
        fill="#010101"
        d="M226.656,116.622v-0.272l-0.272-0.066c-0.075-0.011-7.496-1.641-15.048,0l-0.282,0.066l0.011,0.292
 c0.01,0.989,1.445,99.06,1.772,104.776c0,0.118,0.031,0.292,0.205,0.466c0.641,0.587,3.542,0.761,6.715,0.761
 c3.651,0,7.649-0.227,8.921-0.304l0.348-0.022L226.656,116.622z M228.145,214.682c-4.411-39.682-2.966-75.733-2.033-90.695
 L228.145,214.682z M213.542,221.407c-0.315-5.574-1.684-98.537-1.771-104.493c6.454-1.335,12.734-0.272,14.157,0
 c-0.379,4.303-4.346,52.284,2.337,104.742C221.397,222.07,214.086,222.07,213.542,221.407"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M234.506,452.393c-48.334-4.625-67.067-6.504-75.673-8.534c-3.377-0.796-6.587-2.142-9.522-3.991
 c-2.86-1.803-6.864-4.469-10.381-7.332"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M273.239,451.367c41.804-4.206,60.479-5.612,68.518-7.508c3.377-0.796,6.586-2.142,9.521-3.991
 c2.861-1.801,6.865-4.468,10.382-7.332"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M219.732,223.879c-6.138,0-7.318-0.626-8.068-1.326c-0.558-0.525-0.874-1.209-0.895-1.937c-0.271-5.186-1.315-74.676-1.657-97.551
 c-0.053-3.512-0.086-5.61-0.088-5.749v-2.107l2.017-0.386c2.306-0.505,4.873-0.767,7.581-0.767c4.031,0,7.24,0.585,8.066,0.771
 l1.92,0.381l0.074,1.969l2.37,106.245l-2.383,0.151C226.771,223.689,223.098,223.879,219.732,223.879z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M199.616,42.712c0.091-0.311,0.2-0.653,0.322-0.989c1.542-4.196,5.501-7.107,12.104-8.888c5.98-1.618,12.47-2.778,20.425-3.653
 c7.495-0.819,14.242-1.217,20.627-1.217c5.969,0,11.579,0.347,17.149,1.059c5.984,0.775,12.1,1.957,18.176,3.514
 c4.251,1.09,9.014,2.564,11.751,6.288c1.227,1.674,1.86,3.052,1.969,3.615"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M199.294,51.134c2.186,1.795,5.196,3.28,9.27,3.548c2.143,0.141,4.608,0.213,7.327,0.213c5.026,0,9.939-0.244,12.912-0.427"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M254.511,464.49c-8.534,0-15.259-0.475-16.325-0.815c-0.186-0.086-0.242-0.97-0.151-1.486l0.023-0.152
 c0.724-6.326,1.47-68.933,1.219-79.302c-0.106-4.662-0.45-47.191-0.813-92.215l-0.108-13.575
 c-0.38-47.735-0.773-97.095-1.013-114.634c-0.42-32.379-2.464-69.039-2.937-77.167l0.354-0.715l-0.413-0.622
 c-0.136-5.714-0.037-23.338,0.003-29.546h0.015l0.011,0.11v-1.642c0.583-0.092,1.192-0.15,1.758-0.203l0.638-0.07
 c2.967-0.294,6.079-0.445,10.067-0.486l0.529-0.013l1.438,0.001c2.596,0.011,5.263,0.095,7.844,0.175l0.661,0.021
 c1.885,0.064,3.789,0.129,5.68,0.163l0.286,0.011c0.721,0.014,1.423,0.027,2.125,0.027c0.352,0,0.704-0.002,1.054-0.011
 c0.13,0.728,0.112-0.714,0.074,0.29l-0.012,0.3c-0.075,2.51-0.009,5.015,0.055,7.438l0.01,0.41c0.048,1.536,0.108,3.433,0.108,5.246
 c0.045,2.112,0.099,4.176,0.153,6.241l0.076,2.941l0.326,11.296c0.099,3.247,0.196,6.501,0.304,9.746
 c0.105,3.594,0.222,7.189,0.339,10.79l0.378,11.189c0.097,3.063,0.202,6.128,0.307,9.201l1.072,30.16l-0.164,303.644l0.016,0.131
 l-0.001-0.043c0.01,0.16,0.034,0.279,0.048,0.342c0.047,0.221,0.214,1.163-0.068,2.066
 C266.254,464.217,260.905,464.49,254.511,464.49z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M35.692,453.04c2.378-0.447,9.087-1.604,14.781-1.604c0.861,0,1.667,0.027,2.41,0.08c-0.286,1.686-0.732,4.659-0.932,9.884
 c-0.757-0.016-1.499-0.025-2.223-0.025c-6.506,0-11.641,0.655-14.708,1.181"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M465.837,453.04c-2.378-0.447-9.087-1.604-14.781-1.604c-0.861,0-1.667,0.027-2.41,0.08c0.286,1.686,0.732,4.659,0.932,9.884
 c0.757-0.016,1.499-0.025,2.223-0.025c6.506,0,11.641,0.655,14.709,1.181"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M85.568,466.994c-9.591-3.694-19.625-5.878-29.885-6.505c0.178-3.739,0.481-6.113,0.718-7.61c5.572,0.39,19.418,1.932,30.339,7.862"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M167.088,383.145c4.437,5.232,14.811,15.039,24.65,21.662"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M142.723,389.434c0.279,0.597,2.817,3.658,3.262,4.1c0.958,0.95,1.982,1.832,3.047,2.661c2.652,2.067,4.775,3.676,8.101,4.487
 c7.271,1.774,14.572,4.09,21.929,5.462c6.942,1.293,13.98,2.751,20.978,3.895c3.213,0.525,35.003,4.934,35,5.236"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        points="
 156.606,289.139 167.247,289.139 165.7,375.731 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M141.274,211.644c1.167,2.747,3.101,8.095,3.101,8.095c4.249,12.382,8.83,31.932,8.892,58.758
 c0.105,42.196-1.648,98.545-2.217,115.043"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M121.149,125.957c-27.271,66.167-41.27,144.306-42.995,154.357c-3.517,1.931-12.653,7.147-19.042,12.576"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M201.191,78.546c-21.836,3.013-39.846,9.549-56.266,20.362c-9.343,6.144-16.559,15.427-20.156,20.63"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M122.661,111.477c2.789-3.963,22.033-28.619,73.913-37.091"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        x1="165.238"
        y1="379.414"
        x2="165.238"
        y2="398.852"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M302.134,51.134c-2.186,1.795-5.196,3.28-9.27,3.548c-2.143,0.141-4.608,0.213-7.327,0.213c-5.026,0-9.939-0.244-12.912-0.427"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M415.858,466.994c9.591-3.694,19.625-5.878,29.885-6.505c-0.178-3.739-0.481-6.113-0.718-7.61
 c-5.572,0.39-19.418,1.932-30.339,7.862"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M334.338,383.145c-4.437,5.232-14.81,15.039-24.649,21.662"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M358.704,389.434c-0.279,0.597-2.817,3.658-3.262,4.1c-0.958,0.95-1.982,1.832-3.047,2.661c-2.652,2.067-4.775,3.676-8.1,4.487
 c-7.272,1.774-14.573,4.09-21.929,5.462c-6.943,1.293-13.982,2.751-20.979,3.895c-3.213,0.525-28.152,3.783-28.148,4.086"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        points="
 344.82,289.139 334.18,289.139 335.726,375.731 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M360.153,211.644c-1.167,2.747-3.101,8.095-3.101,8.095c-4.249,12.382-8.83,31.932-8.892,58.758
 c-0.105,42.196,1.648,98.545,2.217,115.043"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M380.277,125.957c27.271,66.167,41.27,144.306,42.995,154.357c3.517,1.931,12.653,7.147,19.042,12.576"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M300.235,78.546c21.836,3.013,39.846,9.549,56.266,20.362c9.343,6.144,16.559,15.427,20.156,20.63"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        d="
 M378.765,111.477c-2.789-3.963-22.033-28.619-73.913-37.091"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        x1="336.189"
        y1="379.414"
        x2="336.189"
        y2="398.852"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4889"
        strokeMiterlimit="10"
        strokeDasharray="1.4592,1.4581"
        points="
 232.423,52.977 234.378,52.728 234.378,50.743 "
      />
    </svg>
  );
}
